import React from 'react';
import * as Sentry from '@sentry/nextjs';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    console.log({ error, errorInfo });
    Sentry.captureException(errorInfo);
  }
  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="max-w-3xl mx-auto min-h-full flex flex-col justify-center py-24 px-4 text-center space-y-4">
          <h2 className="text-3xl">Oops, there was an error</h2>
          <p>Sorry this has been reported 😭💜</p>
          <div>
            <button
              type="button"
              className="w-32 py-4 px-4 rounded-md text-white bg-indigo-500  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => this.setState({ hasError: false })}
            >
              Go back
            </button>
          </div>
        </div>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export default ErrorBoundary;
